<template>
	<div class="about">
		<img v-show="spread.tuiguang.slide_pic2" :src="spread.tuiguang.slide_pic2" alt="" />
		<img v-show="spread.tuiguang.slide_pic3" :src="spread.tuiguang.slide_pic3" alt="" />
		<img v-show="spread.tuiguang.slide_pic4" :src="spread.tuiguang.slide_pic4" alt="" />
		<img v-show="spread.tuiguang.slide_pic5" :src="spread.tuiguang.slide_pic5" alt="" />
		<img v-show="spread.tuiguang.slide_pic6" :src="spread.tuiguang.slide_pic6" alt="" />
		<img v-show="spread.tuiguang.slide_pic7" :src="spread.tuiguang.slide_pic7" alt="" />
		<img v-show="spread.tuiguang.slide_pic8" :src="spread.tuiguang.slide_pic8" alt="" />

		<img v-show="spread.tuiguang.slide_pic9" :src="spread.tuiguang.slide_pic9" class="lastchild" @click="gocdk"
			style="width: 200px;display: flex;justify-content: center;" />
		<img v-show="spread.tuiguang.slide_pic10" :src="spread.tuiguang.slide_pic10" alt="" class="base" />
		<!-- <button @click="gocdk">ceshi</button> -->
		<div class="share">
			<div class="rule_btn btn" @click="ruleBtn">
				<p>规<br />则</p>
			</div>
			<div id="sharecopy" class="share_btn btn" @click="shareBtn('#sharecopy')">
				<p>分<br />享</p>
			</div>
			<div class="reward_btn btn" @click="awardBtn">
				<p>我<br />的<br />奖<br />励</p>
			</div>
		</div>
		<van-popup v-model:show="show" round :style="{ padding: '50px 10px 30px 10px' }" closeable close-icon="close">
			<van-cell-group inset>
				<van-field id="foo" v-model="sms" label-align="center" center clearable label="福利码">
					<template #button>
						<van-button id="copysms" size="small" type="primary"
							@click="copyBtn('#copysms')">复制</van-button>
					</template>
				</van-field>
			</van-cell-group>
		</van-popup>

		<van-popup v-model:show="ruleshow" round closeable close-icon="close">
			<img v-show="spread.tuiguang.slide_pic11" :src="spread.tuiguang.slide_pic11" alt="" />
			<div class="rulecen">
				<div class="rule cdk">
					<div class="ruleComp">
						<div class="item">
							<p class="left"><span>1</span></p>
							<p class="right">活动游戏：</p>
						</div>
						<div class="textBox">{{spread.tuiguang.slide_name}}</div>
					</div>
					<div class="ruleComp top">
						<div class="item">
							<p class="left"><span>2</span></p>
							<p class="right">活动时间：</p>
						</div>
						<div class="textBox" v-if="dateYMD(spread.tuiguang.start) == dateYMD(spread.tuiguang.end)">{{dateYMD(spread.tuiguang.end)}}</div>
						<div class="textBox" v-else-if="dateYMD(spread.tuiguang.start) != dateYMD(spread.tuiguang.end)">{{dateYMD(spread.tuiguang.start)}}至{{dateYMD(spread.tuiguang.end)}}</div>
					</div>
					<div class="ruleComp top">
						<div class="item">
							<p class="left"><span>3</span></p>
							<p class="right">活动奖励：</p>
						</div>
						<div class="textBox">10星币</div>
					</div>
					<div class="ruleComp top">
						<div class="item">
							<p class="left"><span>4</span></p>
							<p class="right">活动规则: </p>
						</div>
						<div class="textBox"> {{spread.tuiguang.slide_des}}</div>
					</div>
					<div class="ruleComp top">
						<div class="item">
							<p class="left"><span>5</span></p>
							<p class="right">兑换方式：</p>
						</div>
						<div class="textBox">{{spread.tuiguang.slide_content}}</div>
					</div>				
				</div>
			</div>

		</van-popup>

		<van-popup v-model:show="awardshow" round closeable close-icon="close">
			<img v-show="spread.tuiguang.slide_pic12" :src="spread.tuiguang.slide_pic12" alt="" />
			<div class="cdk">
				<div class="yard">福利码<span style="margin-top: 10px;">{{sms}}</span></div>
				<van-button style="margin-top: 40px;" id="copysms" size="small" type="primary" @click="copyBtn('#copysms')">复制</van-button>
				<div class="yard" style="margin-top: 40px;">{{smstoast}}</div>
			</div>
		</van-popup>
	</div>
</template>

<script setup>
	import {
		getActivityCdk,
		getTuiguangContent
	} from '@/api/gameindex'
	// import { decrypt, encrypt } from '@/utils/rsa'
	import {
		ref,onMounted,reactive
	} from 'vue'
	// import {
	// 	showNotify
	// } from 'vant';
	import Clipboard from 'clipboard';
	import {
		showSuccessToast,
		showFailToast,
		showToast
	} from 'vant';
	import {dateYMD} from '@/utils/date'
	// import {
	// 	useRoute
	// } from 'vue-router'

	const show = ref(false);
	const ruleshow = ref(true);
	const awardshow = ref(false);
	const sms = ref()
	const uid = ref()
	const slideId = ref()
	const smstoast = ref()
	const spread = reactive({
		tuiguang: null
	})
	// const route = useRoute()
	
	onMounted(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const paramValue = urlParams.get('uid');
		const tuiguangId = urlParams.get('slideId');
		slideId.value = tuiguangId
		uid.value = paramValue
		// console.log(uid.value);
		// console.log(slideId.value);
		
		if(slideId.value) {
			getTuiguangContent({
				slide_id: slideId.value
			}).then(res => {
				console.log(res)
				spread.tuiguang = res.data.c
			})
		}
	})

	const gocdk = () => {
		if (uid.value) {
			getActivityCdk({
				uid: uid.value,
				slide_id: slideId.value
			}).then(res => {
				// console.log(res)
				awardshow.value = true
				sms.value = res.data.c.code
				smstoast.value = res.data.b
				// showNotify({
				// 	type: 'danger',
				// 	message: res.data.b
				// });
				// sms.value = res.data.c.codes
				
				showToast(res.data.b);
			})
		}

	}

	const copyBtn = () => {
		const clipboard = new Clipboard('button', {
			text: () => sms.value,
		});

		clipboard.on('success', () => {
			showSuccessToast('复制成功');
			clipboard.destroy();
		});

		clipboard.on('error', () => {
			showFailToast('复制失败');
			clipboard.destroy();
		});
	}

	const ruleBtn = () => {
		ruleshow.value = true
	}

	const shareBtn = (id) => {
		const clipboardShare = new Clipboard(id, {
			text: () => 'https://promotion.milygame.com/?uid=' + uid.value + '&slideId=' + slideId.value,
		});

		clipboardShare.on('success', () => {
			showSuccessToast('复制成功');
			clipboardShare.destroy();
		});

		clipboardShare.on('error', () => {
			showFailToast('复制失败');
			clipboardShare.destroy();
		});
	}

	const awardBtn = () => {
		awardshow.value = true
	}
</script>

<style scoped>
	.about {
		max-width: 768px;
		padding-bottom: env(safe-area-inset-bottom);
	}

	.about img {
		width: 100%;
		display: block;
	}

	.lastchild {
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 10px;
		z-index: 100;
		padding-bottom: env(safe-area-inset-bottom);
	}

	.base {
		position: fixed;
		bottom: 0;
		height: 70px;
		padding-bottom: env(safe-area-inset-bottom);
	}

	.share {
		width: 20px;
		padding: 10px 4px;
		position: fixed;
		z-index: 1;
		top: 100px;
		right: 0;
		background: rgba(0, 0, 0, .5);
		font-size: 10px;
		font-weight: 700;
		color: #fff;
		border-radius: 15px 0 0 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.share p {
		display: flex;
		flex-direction: column;
	}

	.share .rule_btn {
		padding-bottom: 10px;
		border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
	}

	.share .share_btn {
		padding-top: 7px;
	}

	.share .reward_btn {
		padding-top: 10px;
		margin-top: 10px;
		border-top: 2px solid hsla(0, 0%, 100%, 0.4);
	}

	::v-deep .van-popup {
		width: 270px;
	}

	::v-deep .van-button {
		width: 100px;
		margin-top: 10px;
		background: #D58757;
		border: #D58757;
	}

	::v-deep .van-badge__wrapper {
		top: 28px;
	}

	.cdk {
		position: absolute;
		top: 70px;
		width: 100%;
	}

	.yard {
		color: #D58757;
		font-size: 17px;
		font-weight: 700;
		display: flex;
		flex-direction: column;
	}

	.rulecen {
		display: flex;
		justify-content: center;
	}

	.rule {
		color: #fff;
		width: 210px;
		height: 190px;
		text-align: left;
		overflow: scroll;
	}

	.ruleComp .item {
		display: flex;
		width: 100%;
		margin: 1px 0px;
	}

	.ruleComp .item .left span {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #D58757;
		font-size: 10px;
		color: #F8EDCE;
		margin-right: 10px;
	}

	.ruleComp .item .right {
		font-size: 14px;
		color: #D58757;
		font-weight: 900;
	}

	.ruleComp .textBox {
		font-size: 14px;
		color: #D58757;
		font-weight: 900;
		margin-left: 28px;
	}

	.top {
		margin-top: 5px;
	}
</style>