export function dateMD(timestamp) {
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	// var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	// var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	// var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	// var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
	return M + D
}

export function dateMDHM(timestamp) {
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日  ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + '';
	return M + D + h + m;
}

export function dateYMD(timestamp) {
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '年';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日  ';
	// var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	// var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	// var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
	return Y + M + D
}

export function timeDiffHoursText(startDate){
	var endDate = Date.parse(new Date());
	var calDiffs = (endDate/1000 - startDate);
	var hours = parseInt(calDiffs/3600);

	if(hours < 24){
		return 23 - hours;
	}else {
		return -1;
	}
}

export function timeDiffMinuteText(startDate){
	var endDate = Date.parse(new Date());
	var calDiffs = (endDate/1000 - startDate);
	var hours = calDiffs/3600;
	if(hours >= 1){
		calDiffs = calDiffs - hours*3600;
		var sec = calDiffs/60;
		if (sec < 60){
			return 60 - sec;
		}else {
			return 0;
		}
	}else {
		var sec2 = parseInt(calDiffs/60);
		if (sec2 < 60){
			return 60 - sec2;
		}else {
			return 0;
		}
	}
}
