import Cookies from 'js-cookie'

const TokenKey = 'vue_token'

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	// 设置了过期时间
	return Cookies.set(TokenKey, token, {
		expires: 7
	})
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}
