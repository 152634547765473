import axios from 'axios'
import { getToken } from '@/utils/auth'
import { encrypt, decrypt } from '@/utils/rsa'
// import {
//     getQueryString
// } from '@/utils/location'

// 创建axios
const service = axios.create({
    baseURL: "/api", // url = base url + request url
    timeout: 50000, // request timeout
	withCredentials: true
})
// 添加请求拦截器
service.interceptors.request.use(function(config) {
    // console.log(config);

    if (config.data == '' ||config.data == undefined ||config.data == null) {
        config.data = new Object();
    }
    // console.log(config.data);
    // console.log(config);

    // getQueryString('key');
    // config.data.key = getQueryString('key');
	// config.data.key = "NmXPrxJ16l";
 //    config.data.appid = 2
	// config.data.versionCode = 1
    // console.log(config.data);

    let token = getToken();
    if (token) {
      config.headers['wancms-users-token'] = token;
    }else{
      config.headers['wancms-users-token'] = '';
    }

    // JSON.stringify(config.data);
    config.data = JSON.stringify(config.data);
	// console.log(config.data);
    encrypt(config.data);
    config.data = encrypt(config.data);

    // console.log(config);

    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
service.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    // console.log('res', response);
    response.data = decrypt(response.data);
    response.data = JSON.parse(response.data);
    // console.log('res', response);

    return response;
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
export default service;