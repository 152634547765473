import request from '@/utils/request'

// 测试
export function getActivityCdk(data) {
  return request({
    url: '/gift1/getActivityCdk',
    method: 'post',
    data
  })
}

export function getTuiguangContent(data) {
  return request({
    url: '/gift1/getTuiguangContent',
    method: 'post',
    data
  })
}
