/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。

// 公钥key
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDI8lcvp9r6aeFGoaQcrhvRxZHCZNy21VKbGR3uZ0jPzWFc2FHF6nC625FB/UfI8IX1x7rq3D4F0RLmu19McVR/RNWbZocSeu4EJEo76C9FvPBwqYJG9zM4dWa6qHtgo1QMUijBUcV3pqDKlK9sLpjZSf+YQFTGAV3rel3XjNVocQIDAQAB'
// 私钥key
const privateKey = 'MIICXgIBAAKBgQCwFtdiDTBLDxqjpeJ/mh48WUzFaXqbqI9lGuXs/BpQM/ojGjt4QSOycl1JLshNsz5L8nqS4JghVVBHmpNvTdZdBYEmcBfOGyOXFQAz4UpYRp5vc+w1AYUNK/ZEBpeEk9xqoVYPI29eCfP46KMEf1LWTDKUcXkoSEdKqpdqWuFJ5QIDAQABAoGBAJUKYDa/jvaYzCVjDHiQS7MsqkwU4Q/K9cPPpniVUPg9ZiQ72cYI7FvnbIm6rRMG+V1y2MhkjJ76BL72WH/G0/3wrXFsuc9qaUzxuSDx0y4BmnC3Qml9wxPf0QwaBwSiw7n3al99bWdsgdx0/5CeloWWAmXaz4QYmv+K4UMcjp5pAkEA9XTcw0rGimLbZTafRhXz/WmXtkNcEuCHO+j+8Y1wSMukZJ2GJGl3mUmjS5ryhEAvm2iim18rEnLtrMCIHjP8gwJBALenMFP7aSVwZ9NQbkvl6HMlfc2Tk9fefZlD3fBVuh/PoiRLzup9rT2pmc1hGPCLjpHP9ER9Ej7QkaaG7oE7I3cCQQCnebEXXvHQglsH2BkcjOYJRf7J0XQFPUWITxWEYuj2AlyMeh6IwZ1k7aWJdJN8QP86nk40jN6qm9gnQsadNQNZAkEAne5SawYap62AO4JBN1egi9TD2JN+Yfbc/34TT2gJRO3jO19Nsu0cm5hLwQgs0D7S/aVdI8CCfi4R8qi6VburhQJAAOXMWBUQiBpG97yuuTAlDoh4IeIbGT2wvkIVnUEuUm0WPrffteTVQepa6M4sTEayCwNbJCljGMchTxdqMfpJmQ=='

/* JSEncrypt加密 */
export function rsaPublicData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
}
/* JSEncrypt解密 */
export function rsaPrivateData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
}
/* 加密 */
export function encrypt(data) {
    const PUBLIC_KEY = publicKey
    var encryptor = new Encrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encryptLong(data)
    return result
}
/* 解密 - PRIVATE_KEY - 验证 */
export function decrypt(data) {
    const PRIVATE_KEY = privateKey
    var encryptor = new Encrypt()
    encryptor.setPrivateKey(PRIVATE_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = encryptor.decryptLong(data)
    return result
}