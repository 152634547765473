<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
	<AboutViews/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AboutViews from '@/views/AboutView.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
	AboutViews
  }
}
</script>
